import { useEffect } from "react"
import { useArticles } from "./Providers/Articles"
import { useAvailableFiltersValues } from "./Providers/AvailableFilters"
import { useSavedFilters } from "./Providers/SavedFilters"
import { usePreloader } from "./Providers/Preloader"

const AppInitQueries: React.FC = () => {
  const { setValue: setIsLoading } = usePreloader()
  const { refetch: refetchArticles } = useArticles()
  const { refetch: refetchAvailableFiltersValues } = useAvailableFiltersValues()
  const { refetch: refetchSavedFilters } = useSavedFilters()

  const initFetchHandler = async () => {
    setIsLoading(true)
    await Promise.allSettled([
      refetchAvailableFiltersValues(),
      refetchSavedFilters(),
      refetchArticles(),
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    initFetchHandler()
  }, [])

  return null
}

export default AppInitQueries
