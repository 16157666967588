import { Delete } from "@mui/icons-material"
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React from "react"
import { useApiQueryWithMsalAuth } from "../../api/ApiQueryHelpers"
import { usePreloader } from "../../GlobalContext/Providers/Preloader"
import { useSavedFilters } from "../../GlobalContext/Providers/SavedFilters"
import { useSearchParams } from "../../GlobalContext/Providers/SearchParams"
import { useSnackbar } from "../../GlobalContext/Providers/Snackbar"
import { OutlinedBlockWithLabel } from "./OutlinedBlockWithLabel"

const SavedFiltersMenu: React.FC = () => {
  const {
    value: savedFilters,
    refetch: refetchSavedFilters,
    apply: applySavedFilters,
  } = useSavedFilters()
  const { value: currentSearchParams } = useSearchParams()
  const { setValue: setIsLoading } = usePreloader()
  const { showSuccess, showError } = useSnackbar()
  const theme = useTheme()
  const XLBreakpointMatched = useMediaQuery(theme.breakpoints.down("xl"))
  const { send: deleteSavedFiltersQuery } = useApiQueryWithMsalAuth()

  const compareObjectsWithKeysOrdering = (obj1: any, obj2: any) => {
    if (!Object.keys(obj1).length || !Object.keys(obj2).length) return false

    const orderObjectByKeys = (obj: any) => {
      return Object.keys(obj)
        .sort()
        .reduce((resultingObj: any, key: any) => {
          resultingObj[key] = obj[key]
          return resultingObj
        }, {})
    }

    return (
      JSON.stringify(orderObjectByKeys(obj1)) ===
      JSON.stringify(orderObjectByKeys(obj2))
    )
  }

  const deleteSavedFiltersHandler = async (savedFiltersId: number) => {
    setIsLoading(true)
    const response = await deleteSavedFiltersQuery({
      uri: `/saved-filters/delete/${savedFiltersId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (response && response.ok) {
      showSuccess("Filter wurden erfolgreich gelöscht!")
      refetchSavedFilters()
    } else {
      showError("Fehler beim Speichern der Filter!")
    }
    setIsLoading(false)
  }

  if (!savedFilters.length) return null

  return (
    <OutlinedBlockWithLabel label="Gespeicherte Filter">
      <List
        sx={{
          maxHeight: "100%",
          overflowY: "auto",
          width: "100%",
        }}
      >
        {savedFilters.map((savedFilters) => (
          <ListItem
            key={savedFilters.id}
            sx={(theme) => ({
              padding: 0,
              display: "flex",
              [theme.breakpoints.down("xl")]: {
                fontSize: "14px",
              },
            })}
            secondaryAction={
              <IconButton
                size={XLBreakpointMatched ? "small" : "medium"}
                sx={{ marginRight: -1.2 }}
                onClick={() => {
                  deleteSavedFiltersHandler(savedFilters.id!)
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            }
          >
            <ListItemButton
              sx={{ paddingRight: 0 }}
              onClick={() => {
                applySavedFilters(savedFilters.id!)
              }}
            >
              {compareObjectsWithKeysOrdering(
                savedFilters.filters,
                currentSearchParams.filters
              ) ? (
                <Checkbox
                  size="small"
                  sx={{
                    padding: 0,
                    mr: 1,
                    paddingBottom: "2px",
                    borderRadius: "50%",
                  }}
                  checked
                />
              ) : null}
              <Typography noWrap>{savedFilters.label}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </OutlinedBlockWithLabel>
  )
}

export default SavedFiltersMenu
