import { Logout } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import { getEnvVar } from "../env"
import { BasicBrandedCard } from "./BasicBrandedCard"
import { useMsal } from "@azure/msal-react"

export const isUsersTenantAuthorized = ({ tenantId }: { tenantId: string }) => {
  const authorizedTenantIds = getEnvVar(
    "REACT_APP_APPLICATION_AUTHORIZED_TENANT_IDS"
  )?.split(" ")

  return (
    tenantId && authorizedTenantIds && authorizedTenantIds.includes(tenantId)
  )
}

const AuthErrorPage: React.FC<{ authError: string }> = ({ authError }) => {
  const { instance: msalInstance } = useMsal()

  return (
    <BasicBrandedCard>
      <Button
        startIcon={<Logout />}
        size="large"
        variant="contained"
        sx={{
          backgroundColor: "#29293A",
          "&:hover": {
            backgroundColor: "#29293A",
            transition: "0.2ms all",
          },
        }}
        onClick={async () => {
          msalInstance.logoutRedirect({
            onRedirectNavigate: () => {
              return false
            },
          })
        }}
      >
        Bitte melden Sie sich ab
      </Button>
      <Box mt={3} sx={{ fontSize: "12px", color: "dimgray" }}>
        {authError}
      </Box>
    </BasicBrandedCard>
  )
}

export default AuthErrorPage
