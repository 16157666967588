import { Button, Dialog, Stack, TextField } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAdminToken } from "./AdminPanel/AdminSessionContext"
import CreateArticleControl from "./AdminPanel/CreateArticleControl"
import DeleteArticleControl from "./AdminPanel/DeleteArticleControl"
import FeatureConfigControl from "./AdminPanel/FeatureConfigControl"
import UploadXmlArticles from "./AdminPanel/UploadXmlArticles"
import { useApiQueryWithMsalAuth } from "./api/ApiQueryHelpers"

const AdminPanel: React.FC = () => {
  const { adminToken, setAdminToken } = useAdminToken()
  const navigate = useNavigate()
  const [adminPassword, setAdminPassword] = useState("")
  const { send: loginAdminQuery } = useApiQueryWithMsalAuth()

  const loginAdminHandler = async () => {
    const response = await loginAdminQuery({
      uri: "/admin/login",
      method: "POST",
      body: JSON.stringify({ password: adminPassword }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    let adminToken = null
    if (response && response.ok) {
      const responseData = await response.json()
      adminToken = responseData["admin_token"] ?? null
    }

    if (adminToken === null) {
      navigate("/")
    } else {
      setAdminToken(adminToken)
    }
  }

  return (
    <>
      {adminToken ? (
        <Stack
          sx={{
            flexDirection: "row",
            columnGap: "24px",
            alignItems: "center",
            backgroundColor: (theme) => theme.palette.background.paper,
            marginY: "5px",
            padding: "12px 14px 4px 14px",
            height: "70px",
          }}
        >
          <CreateArticleControl />
          <DeleteArticleControl />
          <UploadXmlArticles />
          <FeatureConfigControl />
        </Stack>
      ) : (
        <Dialog
          open
          onClose={() => {
            navigate("/")
          }}
        >
          <Stack
            sx={{
              padding: "18px",
              flexDirection: "row",
              columnGap: "12px",
            }}
          >
            <TextField
              sx={{ flexGrow: 1 }}
              type="password"
              autoFocus
              onSubmit={loginAdminHandler}
              label="Admin password"
              value={adminPassword}
              onChange={(e) => {
                setAdminPassword(e.target.value)
              }}
              onKeyUp={(e) => {
                e.key === "Enter" && loginAdminHandler()
              }}
            />
            <Button onClick={loginAdminHandler}>Login</Button>
          </Stack>
        </Dialog>
      )}
    </>
  )
}

export default AdminPanel
