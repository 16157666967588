import { Backdrop, CircularProgress } from "@mui/material"
import React from "react"
import { usePreloader } from "../GlobalContext/Providers/Preloader"

const Preloader: React.FC = () => {
  const { value: isLoading } = usePreloader()

  return (
    <Backdrop open={isLoading} sx={{zIndex: "10000"}}>
			<CircularProgress sx={{color: (theme) => theme.palette.primary.light}} />
    </Backdrop>
  )
}

export default Preloader
