import { PublicClientApplication } from "@azure/msal-browser"
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react"
import { CssBaseline } from "@mui/material"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { msalConfig } from "./MsalAuth/authConfig"
import MsalAuthPage from "./MsalAuth/MsalAuthPage"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <CssBaseline />
      <AuthenticatedTemplate>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MsalAuthPage />
      </UnauthenticatedTemplate>
    </MsalProvider>
  </React.StrictMode>
)
