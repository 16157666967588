import { CreateProvider } from "./CreateProvider"

interface FeatureConfig {
  AITemperature: number
}

const defaultConfig: FeatureConfig = {
  AITemperature: 0.1,
}

const localStorageKey = "featureFlags"
const featureFlagsJson = localStorage.getItem(localStorageKey)

const { ContextProvider, useValue } = CreateProvider<FeatureConfig>(
  featureFlagsJson ? JSON.parse(featureFlagsJson) : defaultConfig,
  (newValue) => {
    localStorage.setItem(localStorageKey, JSON.stringify(newValue))
  }
)

export const FeatureFlagsContextProvider = ContextProvider

export const useFeatureFlags = () => {
  const { value, setValue } = useValue()

  const setFeatureFlag = (configKey: keyof FeatureConfig, newValue: any) => {
    setValue({
      ...value,
      [configKey]: newValue,
    })
  }

  return { value, setValue, setFeatureFlag }
}
