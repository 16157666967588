import { InteractionType } from "@azure/msal-browser"
import { useMsalAuthentication } from "@azure/msal-react"
import { Lock } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import { loginRequest } from "./authConfig"
import { BasicBrandedCard } from "./BasicBrandedCard"

const MsalAuthPage: React.FC = () => {
  const { login } = useMsalAuthentication(InteractionType.None, loginRequest)

  return (
    <BasicBrandedCard>
      <Button
        startIcon={<Lock />}
        size="large"
        variant="contained"
        sx={{
          backgroundColor: "#29293A",
          "&:hover": {
            backgroundColor: "#29293A",
            transition: "0.2ms all",
          },
        }}
        onClick={async () => {
          try {
            await login(InteractionType.Redirect)
          } catch (e) {}
        }}
      >
        Bitte authentifizieren Sie sich
      </Button>
      <Box mt={3} sx={{ fontSize: "12px", color: "dimgray" }}>
        Melden Sie sich mit dem Microsoft-Konto Ihrer Organisation bei der
        Anwendung an
      </Box>
    </BasicBrandedCard>
  )
}

export default MsalAuthPage
