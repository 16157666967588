import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"

export const CreateProvider = <T,>(
  defaultValue: T,
  globalValueEffect?: (newValue: T) => void
) => {
  const ValueContext = createContext<{
    value: T
    setValue: Dispatch<SetStateAction<T>>
  }>({
    value: defaultValue,
    setValue: () => {},
  })

  const useValue = () => useContext(ValueContext)

  const ContextProvider: React.FC<{ children: ReactNode[] | ReactNode }> = ({
    children,
  }) => {
    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
      globalValueEffect && globalValueEffect(value)
    }, [value])
    // console.log(value);

    return (
      <ValueContext.Provider value={{ value, setValue }}>
        {children}
      </ValueContext.Provider>
    )
  }

  return {
    ContextProvider,
    useValue,
  }
}
