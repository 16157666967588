import {
  Check,
  CopyAll,
  LinkOutlined,
  Print,
  Visibility,
} from "@mui/icons-material"
import { ButtonProps, IconButton, Stack } from "@mui/material"
import React, { ReactNode, useState } from "react"
import { useNavigate } from "react-router-dom"
import { usePreviewedArticle } from "../GlobalContext/Providers/PreviewedArticle"
import TranslatePreviewedArticleControl from "./PreviewControls/TranslatePreviewedArticleControl"
import CopyArticleToClipboardControl from "./PreviewControls/CopyArticleToClipboardControl"

export const copyToClipboardUnsafe = (text: string) => {
  const textareaEl = document.createElement("textarea")
  textareaEl.value = text
  textareaEl.style.opacity = "0"
  document.body.append(textareaEl)
  textareaEl.select()
  try {
    document.execCommand("copy")
  } catch (err) {
    console.error("Unnable to use Copy command: SSL needed")
  }
  document.body.removeChild(textareaEl)
}

const PreviewControls: React.FC = () => {
  const { value: previewedArticle } = usePreviewedArticle()
  
  const [shareLinkIcon, setShareLinkIcon] = useState<ReactNode>(
    <LinkOutlined fontSize="small" />
  )
  const navigate = useNavigate()

  const copyArticleLinkToClipboard = () => {
    if (previewedArticle?.id) {
      const link = `${window.location.origin}/article/${previewedArticle.id}`
      navigator.clipboard?.writeText
        ? navigator.clipboard.writeText(link)
        : copyToClipboardUnsafe(link)
    }

    setShareLinkIcon(<Check fontSize="small" />)
    setTimeout(() => {
      setShareLinkIcon(<LinkOutlined fontSize="small" />)
    }, 1000)
  }

  return (
    <Stack
      sx={(theme) => ({
        height: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        columnGap: "14px",
      })}
    >
      <PreviewControlButton
        onClick={(e) => {
          navigate(`/article/${previewedArticle?.id ?? "previewed"}`)
        }}
      >
        {<Visibility fontSize="small" />}
      </PreviewControlButton>

      <PreviewControlButton onClick={window.print}>
        {<Print fontSize="small" />}
      </PreviewControlButton>

      <TranslatePreviewedArticleControl />

      <PreviewControlButton
        disabled={!previewedArticle?.id}
        onClick={copyArticleLinkToClipboard}
      >
        {shareLinkIcon}
      </PreviewControlButton>

      <CopyArticleToClipboardControl />
    </Stack>
  )
}

export const PreviewControlButton: React.FC<ButtonProps> = (props) => {
  return (
    <IconButton
      color="primary"
      sx={(theme) => ({
        backgroundColor: "#D8EFEC",
        borderRadius: "4px",
        py: 0.8,
        px: 2,
        "&:hover": { backgroundColor: "primary.dark" },
      })}
      {...props}
    >
      {props.children}
    </IconButton>
  )
}

export default PreviewControls
