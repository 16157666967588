import { Stack, TextField } from "@mui/material"
import React from "react"
import { useFeatureFlags } from "../GlobalContext/Providers/FeatureConfig"

const FeatureConfigControl: React.FC = () => {
  const { value: features, setFeatureFlag } = useFeatureFlags()

  return (
    <Stack direction="row" columnGap="12px">
      <TextField
        type="number"
        label="Temperatur des OpenAI-Modells"
        onChange={(e) => {
          setFeatureFlag("AITemperature", +e.target.value)
        }}
        value={features.AITemperature}
        sx={{ width: "210px" }}
        InputProps={{
          inputProps: {
            min: "0",
            max: "1",
            step: "0.1",
          },
        }}
      />
    </Stack>
  )
}

export default FeatureConfigControl
