import { Box } from "@mui/material"
import { ReactNode } from "react"

export const OutlinedBlockWithLabel: React.FC<{
  label: string
  children: ReactNode | ReactNode[]
}> = ({ label, children }) => {
  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: "4px",
        height: "fit-content",
        paddingTop: 1,
				display: "flex",
				flexDirection: "column",
				width: "100%",
				maxHeight: "100%",
      })}
    >
      <Box
        sx={(theme) => ({
          color: theme.palette.grey[700],
          fontWeight: 400,
          fontSize: "12px",
          marginTop: "-16px",
          backgroundColor: "white",
          width: "fit-content",
          paddingX: 0.6,
          marginLeft: 1,
        })}
      >
        {label}
      </Box>
      {children}
    </Box>
  )
}
