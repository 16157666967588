import dayjs, { Dayjs } from "dayjs"
import { useApiQueryWithMsalAuth } from "../../api/ApiQueryHelpers"
import { CreateProvider } from "./CreateProvider"

export interface FiltersValues {
  agency: string[]
  section: string[]
  priority: number[]
}

export const defaultAvailableFiltersValues: FiltersValues = {
  agency: [],
  section: [],
  priority: [],
}

export interface Filters {
  text?: string
  dateFrom?: Dayjs
  dateTo?: Dayjs
  agency?: string[]
  section?: string[]
  priority?: number[]
}

export const mapFiltersToApiFormat = (filters: Filters) => ({
  text: filters.text,
  date_from: filters.dateFrom,
  date_to: filters.dateTo,
  agency: filters.agency,
  section: filters.section,
  priority: filters.priority,
})

export const mapFiltersFromApiFormat = (filters: any) =>
  ({
    text: filters.text,
    dateFrom: filters.date_from ? dayjs(filters.date_from) : undefined,
    dateTo: filters.date_to ? dayjs(filters.date_to) : undefined,
    agency: filters.agency,
    section: filters.section,
    priority: filters.priority,
  } as Filters)

const { ContextProvider, useValue } = CreateProvider<FiltersValues>(
  defaultAvailableFiltersValues
)

export const AvailableFiltersValuesContextProvider = ContextProvider

export const useAvailableFiltersValues = () => {
  const { value, setValue } = useValue()
  const { send: fetchAvailableFiltersValuesQuery } = useApiQueryWithMsalAuth()

  const refetch = async () => {
    const response = await fetchAvailableFiltersValuesQuery({
      uri: "/article/filters/get-available-values",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (response) {
      const data = await response.json()
      const mappedData = {
        agency: data.agency ?? [],
        section: data.section ?? [],
        priority: data.priority ? data.priority.sort() : [],
      } as FiltersValues

      setValue(mappedData)
    }
  }

  return { value, setValue, refetch }
}
