import { useMsal } from "@azure/msal-react"
import { AccountCircle } from "@mui/icons-material"
import { Box, ListItemButton, Popover, Typography } from "@mui/material"
import { useState } from "react"
import { UserInfoPopupContent } from "./AuthenticatedUserInfo/UserInfoPopupContent"
import { OutlinedBlockWithLabel } from "./OutlinedBlockWithLabel"

export const AuthenticatedUserInfo: React.FC = () => {
  const { accounts } = useMsal()
  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLElement | null>(null)

  return (
    <>
      <Box
        sx={(theme) => ({ cursor: "pointer" })}
        onClick={(e) => {
          setPopoverAnchorElement(popoverAnchorElement ? null : e.currentTarget)
        }}
      >
        <OutlinedBlockWithLabel label="Benutzerinformationen">
          <ListItemButton
            sx={{
              padding: "12px 0 10px 12px",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              columnGap: "10px",
              alignItems: "end",
            }}
          >
            <AccountCircle
              fontSize="medium"
              sx={{ transform: "scale(1.2)" }}
              color="action"
            />
            <Typography noWrap>{accounts[0].name}</Typography>
          </ListItemButton>
        </OutlinedBlockWithLabel>
      </Box>
      <Popover
        elevation={3}
        sx={{ ml: 1 }}
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!popoverAnchorElement}
        onClose={() => setPopoverAnchorElement(null)}
      >
        <UserInfoPopupContent />
      </Popover>
    </>
  )
}
