import { Button, Input } from "@mui/material"
import React, { useState } from "react"
import { useApiQueryWithMsalAuth } from "../api/ApiQueryHelpers"
import { useArticles } from "../GlobalContext/Providers/Articles"
import { useAvailableFiltersValues } from "../GlobalContext/Providers/AvailableFilters"
import { usePreloader } from "../GlobalContext/Providers/Preloader"
import { useSnackbar } from "../GlobalContext/Providers/Snackbar"
import { useAdminToken } from "./AdminSessionContext"

const UploadFolderWithXmlArticles: React.FC = () => {
  const { adminToken } = useAdminToken()
  const { setValue: setIsLoading } = usePreloader()
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
  const { refetch: refetchArticles } = useArticles()
  const { refetch: refetchAvailableFiltersValues } = useAvailableFiltersValues()
  const { showSuccess, showWarning, showError, showInfo } = useSnackbar()
  const { send: uploadXmlArticlesFilesQuery } = useApiQueryWithMsalAuth()

  const submitFilesUploading = async () => {
    if (selectedFiles) {
      const formData = new FormData()
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("xml_files", selectedFiles[i])
      }

      setIsLoading(true)
      const response = await uploadXmlArticlesFilesQuery({
        uri: "/article/bulk-parse-xml-files",
        method: "POST",
        body: formData,
        headers: {
          "Admin-Token": adminToken,
        },
      })
      if (response) {
        if (response.status === 201) {
          const responseData = await response.json()
          if ((responseData.created ?? -1) > 0) {
            await Promise.allSettled([
              refetchArticles(),
              refetchAvailableFiltersValues(),
            ])
          }

          if (responseData.is_successful) {
            showSuccess(`${selectedFiles.length} files processed successfully!`)
          } else if (responseData.created > 0) {
            showWarning(
              `Not all files are processed successfully: ${responseData.created} / ${selectedFiles.length} created!`
            )
          }
          (responseData.created ?? -1) > 0 && showInfo("Articles list and filters are updated")
        } else if (response.status === 413) {
          showError("Please upload files up to 25 MB in total size")
        } else {
          showError("Could not process any of the provided files!")
        }
      }

      setIsLoading(false)
      setSelectedFiles(null)
    }
  }

  return (
    <Button
      component={selectedFiles ? "button" : "label"}
      onClick={selectedFiles ? submitFilesUploading : undefined}
    >
      {selectedFiles
        ? `Ausgewählte Artikel hochladen (${selectedFiles.length})`
        : "XML-Artikel hochladen"}
      <Input
        type="file"
        inputProps={{ accept: ".xml", multiple: true }}
        sx={{ display: "none" }}
        onChange={(e) => {
          const filesInput = e.target as HTMLInputElement
          const filesCount = filesInput.files?.length ?? 0
          if (filesCount > 1000) showError("Please upload up to 1000 files!")
          else if (filesInput.files?.length) setSelectedFiles(filesInput.files)
        }}
      />
    </Button>
  )
}

export default UploadFolderWithXmlArticles
