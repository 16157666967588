import React, { useState } from "react"
import { useArticles } from "../../GlobalContext/Providers/Articles"
import { useAdminToken } from "../AdminSessionContext"
import { Button, Dialog, DialogContent, TextField } from "@mui/material"
import DateTimePickerWithFormat from "../../Components/DateTimePickerWithFormat"
import { usePreloader } from "../../GlobalContext/Providers/Preloader"
import { useApiQueryWithMsalAuth } from "../../api/ApiQueryHelpers"

export enum FormExitStatuses {
  OK = "OK",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
}

export interface ArticleNew {
  header: string
  body: string
  agency: string
  section: string
  priority: number
  report_date: string
}

const CreateArticleForm: React.FC<{
  closeForm: (status: FormExitStatuses) => void
}> = ({ closeForm }) => {
  const [formData, setFormData] = useState<ArticleNew>({
    header: "",
    body: "",
    agency: "",
    section: "",
    priority: 3,
    report_date: "",
  })
  const { setValue: setIsLoading } = usePreloader()
  const { refetch: refetchArticles } = useArticles()
  const { adminToken } = useAdminToken()
  const [isOpened, setIsOpened] = useState(true)
  const { send: createArticleQuery } = useApiQueryWithMsalAuth()

  const createArticleHandler = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const response = await createArticleQuery({
      uri: "/article/save",
      headers: {
        "Content-Type": "application/json",
        "Admin-Token": adminToken,
      },
      method: "POST",
      body: JSON.stringify({ article: { ...formData } }),
    })
    if (response && response.status === 201) {
      await refetchArticles()
      closeForm(FormExitStatuses.OK)
    } else {
      closeForm(FormExitStatuses.ERROR)
    }
    setIsLoading(false)
  }

  const handleFormChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "33%",
        },
      }}
      open={isOpened}
      onClose={() => {
        closeForm(FormExitStatuses.CANCEL)
        setIsOpened(false)
      }}
    >
      <DialogContent>
        <form
          onSubmit={createArticleHandler}
          style={{
            display: "flex",
            rowGap: "12px",
            flexDirection: "column",
          }}
        >
          <TextField
            autoFocus={true}
            name="header"
            label="Header"
            value={formData.header}
            onChange={handleFormChange}
            required
          />
          <TextField
            name="body"
            label="Body"
            value={formData.body}
            onChange={handleFormChange}
            multiline
            required
            inputProps={{
              style: {
                height: "300px",
                overflow: "auto",
              },
            }}
          />
          <TextField
            name="agency"
            label="Agency"
            value={formData.agency}
            onChange={handleFormChange}
            required
          />
          <TextField
            name="section"
            label="Section"
            value={formData.section}
            onChange={handleFormChange}
            required
          />
          <TextField
            type="number"
            name="priority"
            label="Priority"
            sx={{ width: "210px" }}
            InputProps={{
              inputProps: {
                min: "1",
                max: "5",
                step: "1",
              },
            }}
            value={formData.priority}
            onChange={handleFormChange}
            required
          />
          <DateTimePickerWithFormat
            disableFuture
            slotProps={{
              textField: { required: true, error: false },
            }}
            label="Reporting date"
            value={formData.report_date}
            onChange={(dateValue) => {
              setFormData({
                ...formData,
                report_date: (dateValue ?? "") as string,
              })
            }}
          />
          <Button type="submit">Save</Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateArticleForm
