import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { getEnvVar } from "../../env"

const DateTimePickerWithFormat: React.FC<DateTimePickerProps<string>> = (
  dateTimePickerProps
) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        format={getEnvVar("DATETIME_FORMAT") ?? undefined}
        {...dateTimePickerProps}
      />
    </LocalizationProvider>
  )
}

export const DatePickerWithFormat: React.FC<
  DatePickerProps<string> & { onClear?: () => void }
> = ({ onClear, ...dateTimePickerProps }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format={getEnvVar("DATE_FORMAT") ?? undefined}
        slotProps={{
          field: {
            clearable: !!onClear,
            onClear,
          },
        }}
        {...dateTimePickerProps}
      />
    </LocalizationProvider>
  )
}

export default DateTimePickerWithFormat
