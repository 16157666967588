import { Box, Stack } from "@mui/material"
import React from "react"
import FiltersActions from "./FiltersMenu/FiltersActions"
import FiltersControls from "./FiltersMenu/FiltersControls"
import { AuthenticatedUserInfo } from "./FiltersMenu/AuthenticatedUserInfo"
import SavedFiltersMenu from "./FiltersMenu/SavedFiltersMenu"

const FiltersMenu: React.FC = () => {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: "column",
        height: "100%",
        width: "92%",
        rowGap: "12px",
        mx: "auto",
        pt: 2,
        [theme.breakpoints.down("xl")]: {
          pt: 1.9,
          rowGap: "10px",
        },
      })}
    >
      <FiltersActions />
      <Box sx={{ minHeight: "33%" }}>
        <FiltersControls />
      </Box>
      <Box
        sx={{
          height: "30%",
          marginTop: 1,
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <SavedFiltersMenu />
      </Box>
      <Box sx={{ height: "10%", marginTop: "auto" }}>
        <AuthenticatedUserInfo />
      </Box>
    </Stack>
  )
}

export default FiltersMenu
