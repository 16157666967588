import { GlobalStyles, ThemeProvider, createTheme } from "@mui/material"
import { ReactNode } from "react"
import { PreloaderContextProvider } from "./Providers/Preloader"
import { FeatureFlagsContextProvider } from "./Providers/FeatureConfig"
import { AvailableFiltersValuesContextProvider } from "./Providers/AvailableFilters"
import { ArticlesContextProvider } from "./Providers/Articles"
import { PreviewedArticleContextProvider } from "./Providers/PreviewedArticle"
import { SearchParamsContextProvider } from "./Providers/SearchParams"
import { SnackbarContextProvider } from "./Providers/Snackbar"
import { SavedFiltersContextProvider } from "./Providers/SavedFilters"
import AppInitQueries from "./AppInitQueries"

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    primary: {
      main: "#69C1B8",
      dark: "#BDE3DE",
    },
    secondary: {
      main: "#ffffff",
      dark: "#fb8500",
    },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        disableCloseOnSelect: true,
        multiple: true,
        forcePopupIcon: false,
        disableClearable: true,
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          position: "static",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
        size: "small",
      },
      styleOverrides: {
        contained: {
          color: "rgb(41, 41, 58)",
          fontWeight: 400,
          ":disabled": {
            backgroundColor: "#D8EFEC",
          },
        },
        sizeSmall: {
          fontSize: "14px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          marginTop: 2,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
  },
})

const globalStyles = (
  <GlobalStyles
    styles={{
      html: {
        fontSize: "14px",
      },
    }}
  />
)

const GlobalContextProvider: React.FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      {globalStyles}
      <PreloaderContextProvider>
        <SnackbarContextProvider>
          <FeatureFlagsContextProvider>
            <SearchParamsContextProvider>
              <PreviewedArticleContextProvider>
                <AvailableFiltersValuesContextProvider>
                  <SavedFiltersContextProvider>
                    <ArticlesContextProvider>
                      <AppInitQueries />
                      {children}
                    </ArticlesContextProvider>
                  </SavedFiltersContextProvider>
                </AvailableFiltersValuesContextProvider>
              </PreviewedArticleContextProvider>
            </SearchParamsContextProvider>
          </FeatureFlagsContextProvider>
        </SnackbarContextProvider>
      </PreloaderContextProvider>
    </ThemeProvider>
  )
}

export default GlobalContextProvider
