import React, { useState } from "react"
import CreateArticleForm, {
  FormExitStatuses,
} from "./CreateArticle/CreateArticleForm"
import { Button } from "@mui/material"
import { useSnackbar } from "../GlobalContext/Providers/Snackbar"

const CreateArticleControl: React.FC = () => {
  const [createFormVisible, setCreateFormVisible] = useState(false)
  const { showSuccess, showError } = useSnackbar()
  const closeFormHandler = (status: FormExitStatuses) => {
    setCreateFormVisible(false)
    if (status === FormExitStatuses.OK) {
      showSuccess("Article is created successfully!")
    } else if (status === FormExitStatuses.ERROR) {
      showError("Error during article creation!")
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          setCreateFormVisible(true)
        }}
      >
        Neuer Artikel
      </Button>
      {createFormVisible && <CreateArticleForm closeForm={closeFormHandler} />}
    </>
  )
}

export default CreateArticleControl
