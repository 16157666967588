import React from "react"
import { Box, Stack } from "@mui/material"
import { PreviewedArticle } from "../GlobalContext/Providers/PreviewedArticle"
import dayjs from "dayjs"
import { getEnvVar } from "../../env"

export const ArticlePreviewView: React.FC<{ article: PreviewedArticle }> = ({
  article,
}) => {
  return (
    <Stack
      sx={{
        height: "100%",
        "& > div": {
          paddingX: "12px",
        },
      }}
    >
      <Box
        sx={(theme) => ({
          fontSize: "23px",
          fontWeight: "bolder",
          paddingTop: 0,
          paddingBottom: "8px",
          [theme.breakpoints.down("xl")]: {
            fontSize: "20px",
          },
        })}
      >
        {article.header}
      </Box>
      <ArticleBodyView articleBodyHtmlContent={article.body} />
    </Stack>
  )
}

export const ArticlePageView: React.FC<{ article: PreviewedArticle }> = ({
  article,
}) => {
  return (
    <Stack
      sx={{
        height: "100%",
        "& > div": {
          padding: "16px 36px",
        },
      }}
    >
      <Box
        sx={(theme) => ({
          borderBottom: "1px solid lightgray",
          fontSize: "23px",
          fontWeight: "bolder",
          [theme.breakpoints.down("xl")]: {
            fontSize: "20px",
          },
        })}
      >
        {article.header}
      </Box>
      <ArticleBodyView articleBodyHtmlContent={article.body} />
      <Stack
        sx={(theme) => ({
          flexDirection: "row",
          justifyContent: "space-between",
          borderTop: "1px solid lightgray",
          fontSize: "17px",
          [theme.breakpoints.down("xl")]: {
            fontSize: "15px",
          },
        })}
      >
        <Box>{article.agency}</Box>
        {article.report_date && (
          <Box>
            {dayjs(article.report_date).format(
              getEnvVar("DATETIME_FORMAT") ?? undefined
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

const ArticleBodyView: React.FC<{ articleBodyHtmlContent: string }> = ({
  articleBodyHtmlContent,
}) => {
  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        marginBottom: "16px",
        overflowY: "auto",
        "ul, ol": {
          paddingLeft: "28px"
        },
        table: {
          width: "100%",
          borderCollapse: "collapse",
          margin: "20px auto",
          border: "none",
          textAlign: "left",
          "& th": {
            backgroundColor: "#f2f2f2",
            fontWeight: "bold",
            padding: "8px",
            borderBottom: "1px solid #ddd",
          },
          "& td": {
            padding: "8px",
            borderBottom: "1px solid #ddd",
          },
          "& tr:nth-of-type(even)": {
            backgroundColor: "#f9f9f9",
          },
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#ffffff",
          },
        },
        [theme.breakpoints.down("xl")]: {
          fontSize: "15px",
        },
      })}
      dangerouslySetInnerHTML={{ __html: articleBodyHtmlContent }}
    />
  )
}
