import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Chip,
  TextField,
  Typography,
} from "@mui/material"
import { useAvailableFiltersValues } from "../../../GlobalContext/Providers/AvailableFilters"
import ArticlePriorityBlock from "../../MainGrid/ArticlePriorityBlock"

export const AgenciesMultiselectAutocomplete: React.FC<
  Omit<AutocompleteProps<any, true, true, false>, "options" | "renderInput"> & {
    selectedTagsMaxHeight?: string | number
  }
> = ({ selectedTagsMaxHeight, ...autocompleteProps }) => {
  const { value: availableFiltersValues } = useAvailableFiltersValues()

  return (
    <Autocomplete
      ListboxProps={{
        sx: {
          maxHeight: "200px",
        },
      }}
      options={availableFiltersValues.agency}
      renderInput={(params) => <TextField {...params} label="Agenturen" />}
      renderOption={({ key, ...props }, option) => {
        return (
          <li key={key} {...props}>
            <Typography noWrap>{option}</Typography>
          </li>
        )
      }}
      renderTags={(values, getTagProps) => (
        <Box
          sx={{
            maxHeight: selectedTagsMaxHeight,
            overflowY: "auto",
          }}
        >
          {values.map((option, index) => {
            const { key, ...props } = getTagProps({ index })
            return (
              <Chip
                sx={{ fontSize: "14px" }}
                variant="outlined"
                label={option}
                key={key}
                {...props}
              />
            )
          })}
        </Box>
      )}
      {...autocompleteProps}
    />
  )
}

export const PrioritiesMultiselectAutocomplete: React.FC<
  Omit<AutocompleteProps<any, true, true, false>, "options" | "renderInput"> & {
    selectedTagsMaxHeight?: string | number
  }
> = ({ selectedTagsMaxHeight, ...autocompleteProps }) => {
  const { value: availableFiltersValues } = useAvailableFiltersValues()

  return (
    <Autocomplete
      ListboxProps={{
        sx: {
          maxHeight: "200px",
          display: "flex",
          flexWrap: "wrap",
          padding: 0,
        },
      }}
      options={availableFiltersValues.priority}
      renderInput={(params) => <TextField {...params} label="Priorität" />}
      getOptionLabel={String}
      renderOption={({ key, ...props }, option) => {
        return (
          <li
            style={{
              height: "36px",
              width: "36px",
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            key={key}
            {...props}
          >
            <Box>
              <ArticlePriorityBlock priority={option} />
            </Box>
          </li>
        )
      }}
      renderTags={(value, getTagProps) => (
        <Box
          sx={{
            maxHeight: selectedTagsMaxHeight,
            overflowY: "auto",
            display: "flex",
            flexDirection: "row",
            gap: "6px",
          }}
        >
          {value.sort().map((option, index) => {
            const { key, ...props } = getTagProps({ index })
            return (
              <ArticlePriorityBlock priority={option} key={key} {...props} />
            )
          })}
        </Box>
      )}
      {...autocompleteProps}
    />
  )
}
