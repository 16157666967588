import { Grid, styled } from "@mui/material"
import React, { useEffect, useRef } from "react"
import { ArticlePreviewView } from "./ArticleView/ArticleContentView"
import { useArticles } from "./GlobalContext/Providers/Articles"
import { usePreviewedArticle } from "./GlobalContext/Providers/PreviewedArticle"
import { useSearchParams } from "./GlobalContext/Providers/SearchParams"
import FiltersMenu from "./Main/FiltersMenu"
import GridControls from "./Main/GridControls"
import MainGrid from "./Main/MainGrid"
import PreviewControls from "./Main/PreviewControls"

const CustomGridItem = styled(Grid)(
  ({ theme }) => `
	background-color: ${theme.palette.background.paper};
	`
)

const Main: React.FC = () => {
  const { value: previewedArticle } = usePreviewedArticle()
  const { refetch: refetchArticles } = useArticles()
  const { value: articleSearchParams } = useSearchParams()
  const isFirstLoading = useRef(true)
  const isUpdateScheduled = useRef(false)
  const upToDateSearchParams = useRef(articleSearchParams)
  upToDateSearchParams.current = articleSearchParams

  // global filters applying
  useEffect(() => {
    // initially articles are loaded in App Init Queries
    if (isFirstLoading.current) {
      isFirstLoading.current = false
      return
    }

    if (!isUpdateScheduled.current) {
      isUpdateScheduled.current = true
      setTimeout(async () => {
        isUpdateScheduled.current = false
        await refetchArticles(upToDateSearchParams.current)
      }, 1200)
    }
  }, [articleSearchParams])

  return (
    <Grid
      container
      sx={(theme) => ({
        display: "grid",
        height: "100%",
        gridTemplateColumns: previewedArticle ? "12% 1fr 24%" : "12% 1fr 0",
        transition: "120ms",
        gridTemplateRows: "65px 1fr",
        minHeight: 0,
        columnGap: "32px",
        [theme.breakpoints.down("xl")]: {
          gridTemplateColumns: previewedArticle ? "12% 1fr 25%" : "12% 1fr 0",
          gridTemplateRows: "58px 1fr",
        },
      })}
    >
      <CustomGridItem item gridRow="1/3" gridColumn="1" minHeight={0}>
        <FiltersMenu />
      </CustomGridItem>
      <CustomGridItem item gridRow="1" gridColumn="2">
        <GridControls />
      </CustomGridItem>
      <CustomGridItem
        item
        gridRow="2"
        gridColumn="2"
        overflow="auto"
        minHeight={0}
      >
        <MainGrid />
      </CustomGridItem>
      <CustomGridItem
        item
        gridRow="1"
        gridColumn="3"
        p="0 12px"
        sx={(theme) => ({
          [theme.breakpoints.down("xl")]: {
            p: "0 6px",
          },
        })}
      >
        {previewedArticle && <PreviewControls />}
      </CustomGridItem>
      <CustomGridItem item gridRow="2" gridColumn="3" minHeight={0}>
        {previewedArticle && <ArticlePreviewView article={previewedArticle} />}
      </CustomGridItem>
    </Grid>
  )
}

export default Main
