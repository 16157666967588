import {
  Box,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getEnvVar } from "../../env"
import {
  useArticles,
  useArticlesSearchResults,
} from "../GlobalContext/Providers/Articles"
import { usePreviewedArticle } from "../GlobalContext/Providers/PreviewedArticle"
import ArticlePriorityBlock from "./MainGrid/ArticlePriorityBlock"
import GridCustomFooter from "./MainGrid/GridCustomFooter"
import GridSearch from "./MainGrid/GridSearch"

const MainGrid: React.FC = () => {
  const { articles, setArticles } = useArticles()
  const { value: previewedArticle, setValue: setPreviewedArticle } =
    usePreviewedArticle()
  const theme = useTheme()
  const XLBreakpointMatched = useMediaQuery(theme.breakpoints.down("xl"))
  const navigate = useNavigate()
  const { value: articlesSearchResults } = useArticlesSearchResults()

  const rowsPerPage = 60
  const [currentPage, setCurrentPage] = useState(0)

  const numSelected = useMemo(() => {
    return articles.filter((a) => a.isSelected).length
  }, [articles])

  const rowCount = articles.length

  const visibleRows = useMemo(() => {
    return [...articles].slice(
      currentPage * rowsPerPage,
      currentPage * rowsPerPage + rowsPerPage
    )
  }, [currentPage, articles])

  const numOfPages = useMemo(() => {
    return Math.ceil(articles.length / rowsPerPage)
  }, [articles.length])

  const selectAllHandler = () => {
    if (numSelected > 0) {
      // deselect all articles
      setArticles([...articles.map((a) => ({ ...a, isSelected: false }))])
    } else {
      // select all on current page
      setArticles([
        ...articles.map((a) => ({
          ...a,
          isSelected: visibleRows.includes(a) ? true : false,
        })),
      ])
    }
  }

  useEffect(() => {
    // reset grid to 1st page if new articles are fetched (dont observer articles change because they can be just selected)
    setCurrentPage(0)
  }, [articlesSearchResults.filters, articlesSearchResults.pagination])

  const previewedArticleRow = useRef<HTMLElement | null>(null)
  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  const keyScrollScheduled = useRef(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent, scheduled?: boolean) => {
      const tagName = (event.target as HTMLElement).tagName.toLowerCase()
      if (
        (event.key !== "ArrowUp" && event.key !== "ArrowDown") ||
        articles.length === 0 ||
        tagName === "input" ||
        tagName === "textarea"
      ) {
        return
      }

      event.preventDefault() // Prevent default scrolling behavior

      // arrow key holding processing
      if (event.repeat && !scheduled) {
        if (keyScrollScheduled.current === false) {
          keyScrollScheduled.current = true
          setTimeout(() => {
            handleKeyDown(event, true)
            keyScrollScheduled.current = false
          }, 250)
        }

        return
      }

      const currentVisibleArticlesIndex = visibleRows.findIndex(
        (a) => a.id === previewedArticle?.id
      )
      if (currentVisibleArticlesIndex === -1) {
        setPreviewedArticle(visibleRows[0])
        return
      }

      const currentIndex = articles.findIndex(
        (a) => a.id === previewedArticle?.id
      )
      if (
        (event.key === "ArrowUp" && currentIndex > 0) ||
        (event.key === "ArrowDown" && currentIndex < articles.length - 1)
      ) {
        const nextArticle =
          articles[
            event.key === "ArrowDown" ? currentIndex + 1 : currentIndex - 1
          ]
        if (!visibleRows.includes(nextArticle)) {
          event.key === "ArrowDown"
            ? setCurrentPage(currentPage + 1)
            : setCurrentPage(currentPage - 1)
        }
        setPreviewedArticle(nextArticle)
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [articles, visibleRows, currentPage, previewedArticle])

  useEffect(() => {
    if (previewedArticleRow.current && tableContainerRef.current) {
      const rowRect = previewedArticleRow.current.getBoundingClientRect()
      const containerRect = tableContainerRef.current.getBoundingClientRect()

      if (
        !(
          rowRect.top >= containerRect.top &&
          rowRect.bottom <= containerRect.bottom
        )
      ) {
        previewedArticleRow.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [previewedArticle])

  return (
    <Stack sx={{ flexDirection: "column", height: "100%" }}>
      <Box
        sx={(theme) => ({
          borderBottom: `2px solid ${theme.palette.secondary.light}`,
        })}
      >
        <GridSearch />
      </Box>
      {articles.length ? (
        <>
          <Stack
            pb={0.4}
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
              flexDirection: "row",
              fontSize: "16px",
              fontWeight: "500",
              alignItems: "center",
              th: { fontSize: "16px", border: "none" },
            })}
          >
            <Box pl={0.5}>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={selectAllHandler}
              />
            </Box>
            <Box width={62}></Box>
            <Box sx={{ flex: 1 }}>Titel</Box>
            <Box width={XLBreakpointMatched ? 170 : 190}>Datum</Box>
            <Box
              sx={{
                transition: "120ms",
                width: previewedArticle ? 130 : 190,
              }}
            >
              Agentur
            </Box>
          </Stack>
          <TableContainer ref={tableContainerRef}>
            <Table
              sx={{
                tableLayout: "fixed",
                overflowY: "auto",
                maxHeight: "100%",
                td: {
                  fontSize: "16px",
                  border: "none",
                  paddingY: 0.3,
                },
              }}
            >
              <TableBody
                sx={{
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                {visibleRows.map((article, ind) => (
                  <TableRow
                    key={article.id}
                    sx={(theme) => ({
                      cursor: "pointer",
                      backgroundColor:
                        previewedArticle?.id === article.id
                          ? "#DEFAFF"
                          : ind % 2 === 0
                          ? "#F7F7F7"
                          : "white",
                      "&:hover": {
                        backgroundColor: "#E6FBFF",
                      },
                    })}
                    onClick={(e) => {
                      if (e.detail > 1) {
                        navigate(`/article/${article.id}`)
                      } else {
                        setPreviewedArticle(
                          article.id === previewedArticle?.id ? null : article
                        )
                      }
                    }}
                  >
                    <TableCell
                      width={58}
                      padding="checkbox"
                      onClick={(e) => {
                        e.stopPropagation()
                        setArticles([
                          ...articles.map((a) => ({
                            ...a,
                            isSelected:
                              a.id === article.id ? !a.isSelected : a.isSelected,
                          })),
                        ])
                      }}
                    >
                      <Box
                        className="scroll-to-if-previewed"
                        ref={
                          article.id === previewedArticle?.id
                            ? previewedArticleRow
                            : undefined
                        }
                      />
                      <Checkbox color="primary" checked={!!article.isSelected} />
                    </TableCell>
                    <TableCell width={42} sx={{ paddingRight: 0 }}>
                      <Box>
                        <ArticlePriorityBlock priority={article.priority} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={16} noWrap>
                        {article.header}
                      </Typography>
                    </TableCell>
                    <TableCell width={XLBreakpointMatched ? 170 : 190}>
                      {dayjs(article.report_date).format(
                        getEnvVar("DATETIME_FORMAT") ?? undefined
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        transition: "120ms",
                        width: previewedArticle ? 130 : 190,
                      }}
                    >
                      {article.agency}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <GridCustomFooter
            currentPage={currentPage + 1}
            numOfPages={numOfPages}
            numSelected={numSelected}
            onPageChange={(pageNumber) => {
              setCurrentPage(pageNumber - 1)
            }}
          />
        </>
      ) : (
        <Box
          sx={{
            paddingTop: "48px",
            paddingLeft: "8px",
            fontSize: "18px",
          }}
        >
          Ihre Suche ergab keine Ergebnisse
        </Box>
      )}
    </Stack>
  )
}

export default MainGrid
