import { Filters } from "./AvailableFilters"
import { CreateProvider } from "./CreateProvider"

export interface Pagination {
  pageSize: number
  pageNumber: number
}

export interface ArticlesSearchParams {
  pagination: Pagination
  filters: Filters
}

export const defaultSearchParams: ArticlesSearchParams = {
  filters: {},
  pagination: {
    pageNumber: 1,
    pageSize: 420,
  },
}

const { ContextProvider, useValue } =
  CreateProvider<ArticlesSearchParams>(defaultSearchParams)

export const SearchParamsContextProvider = ContextProvider

export const useSearchParams = () => {
  const { value, setValue } = useValue()

  const setFilter = <T extends keyof Filters>(
    filterKey: T,
    newValue: Filters[T]
  ) => {
    setValue({
      ...value,
      filters: {
        ...value.filters,
        [filterKey]: newValue,
      },
    })
  }

  return { value, setValue, setFilter }
}
