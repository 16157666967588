export const getEnvVar: (key: string) => string | null = (key) => {
  const platformVariables = process.env.REACT_APP_PLATFORM_VARIABLES

  if (key === "ENV") {
    return platformVariables ? "platform" : "local"
  } else if (key === "DATETIME_FORMAT") {
		return "DD/MM/YYYY, HH:mm"
  } else if (key === "DATE_FORMAT") {
		return "DD/MM/YYYY"
  }

  if (platformVariables) {
    try {
      const decodedVars = JSON.parse(atob(platformVariables))

      return decodedVars[key] || null
    } catch (error) {
      return null
    }
  } else {
    return process.env[key] || null
  }
}
