import { RocketLaunch } from "@mui/icons-material"
import { Backdrop, Box, Paper, Stack } from "@mui/material"
import { ReactNode } from "react"

export const BasicBrandedCard: React.FC<{
  children: ReactNode | ReactNode[]
}> = ({ children }) => {
  return (
    <Backdrop
      component="div"
      open
      sx={{
        backgroundColor: "#F7F7F7",
      }}
    >
      <Paper
        elevation={2}
        sx={{
          padding: "48px",
          marginBottom: "8%",
          minWidth: "550px",
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box mb={3} sx={{ display: "flex", columnGap: 1.2, color: "#29293A" }}>
            <RocketLaunch fontSize="large" sx={{ marginTop: "-4px" }} />
            <Box
              fontWeight="bold"
              sx={{ fontSize: "22px", textTransform: "uppercase" }}
            >
              Newsboom
            </Box>
          </Box>
          <Box>{children}</Box>
        </Stack>
      </Paper>
    </Backdrop>
  )
}
