import { Clear, SaveOutlined } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import { useState } from "react"
import {
  defaultSearchParams,
  useSearchParams,
} from "../../GlobalContext/Providers/SearchParams"
import StoreSavedFiltersModal from "./FiltersActions/StoreSavedFiltersModal"

const FiltersActions: React.FC = () => {
  const [isFiltersSavingModalOpened, setIsFiltersSavingModalOpened] =
    useState(false)
  const { setValue: setSearchParams } = useSearchParams()

  return (
    <Stack
      sx={(theme) => ({
        width: "100%",
        mx: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "4px",
        flexDirection: "row",
        [theme.breakpoints.down("xl")]: {
          ".filters-actions-btn": {
            fontSize: "12px",
          },
        },
      })}
    >
      <Button
        className="filters-actions-btn"
        title="Filter zurücksetzen"
        onClick={() => {
          setSearchParams(defaultSearchParams)
        }}
        startIcon={<Clear />}
        sx={{
          width: "48%",
        }}
      >
        Löschen
      </Button>
      <Button
        className="filters-actions-btn"
        title="Filter speichern"
        onClick={() => {
          setIsFiltersSavingModalOpened(true)
        }}
        startIcon={<SaveOutlined />}
        sx={{
          width: "48%",
        }}
      >
        Sichern
      </Button>

      {isFiltersSavingModalOpened ? (
        <StoreSavedFiltersModal
          open={isFiltersSavingModalOpened}
          onClose={() => setIsFiltersSavingModalOpened(false)}
          keepMounted={false}
        />
      ) : null}
    </Stack>
  )
}

export default FiltersActions
