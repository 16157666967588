import { useMsal } from "@azure/msal-react"
import { Logout } from "@mui/icons-material"
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"

export const UserInfoPopupContent: React.FC = () => {
  const { instance: msalInstance } = useMsal()

  return (
    <List>
      <ListItemButton
        sx={{ py: 0.2 }}
        onClick={async () => {
          msalInstance.logoutRedirect({
            onRedirectNavigate: () => {
              return false
            },
          })
        }}
      >
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText>Abmelden</ListItemText>
      </ListItemButton>
    </List>
  )
}
