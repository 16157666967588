import { CreateProvider } from "./CreateProvider"

export interface PreviewedArticle {
  id?: React.Key
  header?: string
  body: string
  agency?: string
  report_date?: string
}

export const {
  ContextProvider: PreviewedArticleContextProvider,
  useValue: usePreviewedArticle,
} = CreateProvider<PreviewedArticle | null>(null)
