import { Button } from "@mui/material"
import React from "react"
import {
  useArticles,
  useSelectedArticles,
} from "../GlobalContext/Providers/Articles"
import { usePreloader } from "../GlobalContext/Providers/Preloader"
import { useAdminToken } from "./AdminSessionContext"
import { useSnackbar } from "../GlobalContext/Providers/Snackbar"
import { useApiQueryWithMsalAuth } from "../api/ApiQueryHelpers"

const DeleteArticleControl: React.FC = () => {
  const { adminToken } = useAdminToken()
  const selectedArticles = useSelectedArticles()
  const { setValue: setIsLoading } = usePreloader()
  const { refetch: refetchArticles } = useArticles()
  const { showSuccess, showError } = useSnackbar()
  const { send: deleteArticlesQuery } = useApiQueryWithMsalAuth()

  const deleteArticlesHandler = async () => {
    setIsLoading(true)
    const response = await deleteArticlesQuery({
      uri: "/article/bulk-delete",
      method: "DELETE",
      urlParams: {
        article_ids: selectedArticles.map((article) => `${article.id}`),
      },
      headers: {
        "Content-Type": "application/json",
        "Admin-Token": adminToken,
      },
    })

    if (response && response.status === 200) {
      const responseData = await response.json()
      if (responseData.is_successful) {
        await refetchArticles()
        showSuccess(
          `Selected articles are successfully deleted! (${
            responseData.deleted ?? 0
          })`
        )
      } else {
        showError("Error during articles deletion!")
      }
    } else {
      showError("Error during articles deletion!")
    }
    setIsLoading(false)
  }

  return (
    <Button onClick={deleteArticlesHandler} disabled={!selectedArticles.length}>
      Ausgewählte löschen
    </Button>
  )
}

export default DeleteArticleControl
