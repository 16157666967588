import { AlertColor } from "@mui/material"
import { CreateProvider } from "./CreateProvider"
import { v4 as uuidv4 } from "uuid"

const AUTO_HIDE_DELAY = 4000

type SnackbarTypes = AlertColor | "progress"

interface SnackbarItem {
  id: string
  message: string
  type: SnackbarTypes
}

const { ContextProvider, useValue } = CreateProvider<SnackbarItem[]>([])

export const SnackbarContextProvider = ContextProvider

export const useSnackbar = () => {
  const { value, setValue } = useValue()

  const prepareSnackbarItem = (message: string, messageType: SnackbarTypes) =>
    ({ id: uuidv4(), message, type: messageType } as SnackbarItem)

  const removeSnackbarById = (id: string) =>
    setValue((prevState) => prevState.filter((item) => item.id !== id))

  const showMessage = (messageType: AlertColor) => {
    return (message: string) => {
      const preparedItem = prepareSnackbarItem(message, messageType)
      setTimeout(() => {
        removeSnackbarById(preparedItem.id)
      }, AUTO_HIDE_DELAY)

      setValue((prevState) => [...prevState, preparedItem])
    }
  }

  const runProgress = (message: string) => {
    const preparedItem = prepareSnackbarItem(message, "progress")

    setValue((prevState) => [...prevState, preparedItem])

    const setMessage = (message: string) => {
      setValue((prevState) =>
        prevState.map((item) =>
          item.id === preparedItem.id ? { ...item, message } : item
        )
      )
    }

    return {
      setMessage,
      stopProgress: () => removeSnackbarById(preparedItem.id),
    }
  }

  return {
    snackbarsState: value,
    showError: showMessage("error"),
    showInfo: showMessage("info"),
    showSuccess: showMessage("success"),
    showWarning: showMessage("warning"),
    runProgress,
  }
}
