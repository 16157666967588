import { Key } from "react"
import { useApiQueryWithMsalAuth } from "../../api/ApiQueryHelpers"
import {
  Filters,
  mapFiltersFromApiFormat,
  mapFiltersToApiFormat,
} from "./AvailableFilters"
import { CreateProvider } from "./CreateProvider"
import {
  ArticlesSearchParams,
  defaultSearchParams,
  Pagination,
} from "./SearchParams"

export interface Article {
  id: Key
  header: string
  body: string
  agency: string
  priority: number
  report_date: string
  isSelected?: boolean
}

export interface ArticlesSearchResults {
  pagination: Pagination
  filters: Filters
  totalQtyFound: number
  canLoadMore: boolean
  resultItems: Article[]
}

const defaultArticlesSearchResults: ArticlesSearchResults = {
  pagination: defaultSearchParams.pagination,
  filters: defaultSearchParams.filters,
  totalQtyFound: 0,
  canLoadMore: false,
  resultItems: [],
}

// useArticlesSearchResults -current state of articles + state of search params
export const {
  ContextProvider: ArticlesContextProvider,
  useValue: useArticlesSearchResults,
} = CreateProvider<ArticlesSearchResults>(defaultArticlesSearchResults)

export const useArticles = () => {
  const { value: currentSearchResults, setValue: setSearchResults } =
    useArticlesSearchResults()
  const { send: fetchArticlesQuery } = useApiQueryWithMsalAuth()

  const setArticles = (articles: Article[]) => {
    setSearchResults({ ...currentSearchResults, resultItems: articles })
  }

  const refetch = async (searchParams?: ArticlesSearchParams) => {
    const searchParamsToQuery =
      searchParams || (currentSearchResults as ArticlesSearchParams)
    const response = await fetchArticlesQuery({
      uri: "/article/search",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pagination: {
          page_size: searchParamsToQuery.pagination.pageSize,
          page_number: searchParamsToQuery.pagination.pageNumber,
        },
        filters: mapFiltersToApiFormat(searchParamsToQuery.filters),
      }),
    })
    if (response) {
      const data = await response.json()

      if (Array.isArray(data.result_items)) {
        const nextArticleSearchResults = {
          pagination: {
            pageNumber: data.pagination.page_number,
            pageSize: data.pagination.page_size,
          },
          filters: mapFiltersFromApiFormat(data.filters),
          totalQtyFound: data.total_qty_found,
          canLoadMore: data.can_load_more,
          resultItems: data.result_items,
        }
        setSearchResults(nextArticleSearchResults)
        return true
      }
    }
    return false
  }

  return {
    articles: currentSearchResults.resultItems,
    setArticles,
    refetch,
  }
}

export const useSelectedArticles = () => {
  const { articles } = useArticles()

  return articles.filter((a) => a.isSelected)
}
