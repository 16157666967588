import { Stack } from "@mui/material"
import { DatePickerWithFormat } from "../../Components/DateTimePickerWithFormat"
import { useSearchParams } from "../../GlobalContext/Providers/SearchParams"
import {
  AgenciesMultiselectAutocomplete,
  PrioritiesMultiselectAutocomplete,
} from "./FiltersControls/FilterFormMultiselectAutocomplete"

const FiltersControls: React.FC = () => {
  const { value: currentSearchParams, setFilter } = useSearchParams()

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: "column",
        rowGap: 1.8,
        marginTop: "8px", // align with Text search input
        [theme.breakpoints.down("xl")]: {
          ".MuiInputBase-input": {
            fontSize: "14px",
          },
          ".MuiInputLabel-root": {
            fontSize: "15px",
          },
        },
      })}
    >
      <DatePickerWithFormat
        sx={{ mb: -0.6 }}
        name="dateFrom"
        label="Datum von"
        value={(currentSearchParams.filters.dateFrom ?? null) as any}
        onChange={(value) => {
          setFilter("dateFrom", value ? (value as any) : undefined)
        }}
        onClear={() => {
          setFilter("dateFrom", undefined)
        }}
        onAccept={(value) => {
          setFilter("dateFrom", value ? (value as any) : undefined)
        }}
      />
      <DatePickerWithFormat
        name="dateTo"
        label="Datum bis"
        value={(currentSearchParams.filters.dateTo ?? null) as any}
        onChange={(value) => {
          setFilter("dateTo", value ? (value as any) : undefined)
        }}
        onClear={() => {
          setFilter("dateTo", undefined)
        }}
        onAccept={(value) => {
          setFilter("dateTo", value ? (value as any) : undefined)
        }}
      />
      <AgenciesMultiselectAutocomplete
        value={currentSearchParams.filters.agency ?? []}
        onChange={(e, value) => {
          setFilter("agency", value)
        }}
        selectedTagsMaxHeight="200px"
      />
      <PrioritiesMultiselectAutocomplete
        value={currentSearchParams.filters.priority ?? []}
        onChange={(e, value) => {
          setFilter("priority", value)
        }}
      />
    </Stack>
  )
}

export default FiltersControls
