import { useApiQueryWithMsalAuth } from "../../api/ApiQueryHelpers"
import { Filters, mapFiltersFromApiFormat } from "./AvailableFilters"
import { CreateProvider } from "./CreateProvider"
import { useSearchParams } from "./SearchParams"

export interface SavedFilters {
  id?: number
  label: string
  filters: Filters
}

export const defaultSavedFilters: SavedFilters = {
  label: "",
  filters: {},
}

const { ContextProvider, useValue } = CreateProvider<SavedFilters[]>([])

export const SavedFiltersContextProvider = ContextProvider

export const useSavedFilters = () => {
  const { value, setValue } = useValue()
  const { send: fetchSavedFiltersQuery } = useApiQueryWithMsalAuth()
  const { value: currentSearchParams, setValue: setCurrentSearchParams } =
    useSearchParams()

  const refetch = async () => {
    const response = await fetchSavedFiltersQuery({
      uri: "/saved-filters/get-all",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (response) {
      const data = await response.json()

      if (Array.isArray(data)) {
        const mappedData = data.map(
          (savedFilters) =>
            ({
              ...savedFilters,
              filters: mapFiltersFromApiFormat(savedFilters.filters),
            } as SavedFilters)
        )

        setValue(mappedData)
      }
    }
  }

  const apply = async (savedFiltersId: number) => {
    const filtersToApply = value.find(
      (savedFilters) => savedFilters.id === savedFiltersId
    )
    if (filtersToApply) {
      setCurrentSearchParams({
        pagination: { ...currentSearchParams.pagination },
        filters: {
          text: filtersToApply.filters.text,
          dateFrom: filtersToApply.filters.dateFrom,
          dateTo: filtersToApply.filters.dateTo,
          agency: filtersToApply.filters.agency,
          section: filtersToApply.filters.section,
          priority: filtersToApply.filters.priority,
        },
      })
    } else {
      console.error("Filters with the given Id are not found!")
    }
  }

  return { value, setValue, refetch, apply }
}
