import React from "react"
import Main from "./App/Main"
import Preloader from "./App/Components/Preloader"
import { Route, Routes } from "react-router-dom"
import AdminPanel from "./App/AdminPanel"
import AdminTokenContextProvider from "./App/AdminPanel/AdminSessionContext"
import { getEnvVar } from "./env"
import { Backdrop, Stack } from "@mui/material"
import ArticleView from "./App/ArticleView/ArticleView"
import PrintView from "./App/Main/PreviewControls/PrintView"
import GlobalContextProvider from "./App/GlobalContext/GlobalContextProvider"
import Snackbar from "./App/Components/Snackbar"
import { useMsal } from "@azure/msal-react"
import AuthErrorPage, { isUsersTenantAuthorized } from "./MsalAuth/AuthErrorPage"

const adminUrlKey = getEnvVar("REACT_APP_ADMIN_LOGIN_URL") ?? "admin-panel"
const App: React.FC = () => {
  const { accounts } = useMsal()
  const isTenantAuthorized = isUsersTenantAuthorized({
    tenantId: accounts[0]?.tenantId,
  })

  if (!isTenantAuthorized)
    return (
      <AuthErrorPage authError="Ihre Organisation ist nicht berechtigt, diese Anwendung zu nutzen!" />
    )

  return (
    <GlobalContextProvider>
      <Preloader />
      <Snackbar />
      <PrintView />
      <Backdrop
        open
        sx={{
          backgroundColor: "white",
          zIndex: "-100",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          displayPrint: "none",
        }}
      >
        <Stack flexGrow={1} minHeight={0} width="100%">
          <Routes>
            <Route path="/" element />
            <Route
              path={"/" + adminUrlKey}
              element={
                <AdminTokenContextProvider>
                  <AdminPanel />
                </AdminTokenContextProvider>
              }
            />
            <Route path="/article/:id" element={<ArticleView />} />
          </Routes>
          <Main />
        </Stack>
      </Backdrop>
    </GlobalContextProvider>
  )
}

export default App
