import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { getEnvVar } from "../../env"
import {
  AllWebSocketActionTypes,
  useWebSocketWithMsalAuth,
} from "../api/ApiQueryHelpers"
import { useSelectedArticles } from "../GlobalContext/Providers/Articles"
import { useFeatureFlags } from "../GlobalContext/Providers/FeatureConfig"
import { usePreloader } from "../GlobalContext/Providers/Preloader"
import { usePreviewedArticle } from "../GlobalContext/Providers/PreviewedArticle"
import { useSnackbar } from "../GlobalContext/Providers/Snackbar"

const GridControls: React.FC = () => {
  const selectedArticles = useSelectedArticles()
  const [customRequest, setCustomRequest] = useState("")
  const { value: previewedArticle, setValue: setPreviewedArticle } =
    usePreviewedArticle()
  const { setValue: setIsLoading } = usePreloader()
  const { value: features } = useFeatureFlags()
  const { showError } = useSnackbar()
  const { run: runAIGeneration, stop: stopAIGeneration } =
    useWebSocketWithMsalAuth()

  const executeAIActionHandler = async (actionType: AllWebSocketActionTypes) => {
    setPreviewedArticle({ body: "" })
    setIsLoading(true)

    if (actionType === AllWebSocketActionTypes.AICustomRequest && !customRequest)
      return

    await runAIGeneration({
      uri: "/article/mass-action/ws",
      actionType,
      setCurrentState: (value) => {
        if (value) setIsLoading(false)
        value && setPreviewedArticle({ body: value })
      },
      urlParams: {
        action_type: actionType,
        article_ids: selectedArticles.map((article) => `${article.id}`),
        temperature: `${features.AITemperature}`,
        request:
          actionType === AllWebSocketActionTypes.AICustomRequest
            ? customRequest
            : undefined,
      },
      onWebSocketError: (errorMessage) => {
        setIsLoading(false)
        showError(errorMessage)
      },
    })
  }

  useEffect(() => {
    stopAIGeneration()
  }, [previewedArticle?.id])

  const articlesLimit = parseInt(
    getEnvVar("REACT_APP_MASS_ACTION_ARTICLES_LIMIT") ?? "3"
  )
  const articlesLimitExceeded = selectedArticles.length > articlesLimit
  const tooltipTitle = articlesLimitExceeded
    ? `Articles limit exceeded (${articlesLimit})`
    : ""

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        columnGap: "20px",
        height: "100%",
      }}
    >
      <TooltipedDisabledButton
        onClick={() => {
          executeAIActionHandler(AllWebSocketActionTypes.AISummarize)
        }}
        tooltipTitle={tooltipTitle}
        disabled={selectedArticles.length < 1 || articlesLimitExceeded}
      >
        Zusammenfassung
      </TooltipedDisabledButton>
      <TooltipedDisabledButton
        onClick={() => {
          executeAIActionHandler(AllWebSocketActionTypes.AIComparison)
        }}
        tooltipTitle={tooltipTitle}
        disabled={selectedArticles.length < 2 || articlesLimitExceeded}
      >
        Vergleich
      </TooltipedDisabledButton>
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
          columnGap: "6px",
        }}
      >
        <TextField
          placeholder="z.B.: Kürze auf 500 Zeichen"
          value={customRequest}
          onChange={(e) => {
            setCustomRequest(e.target.value)
          }}
          required
          sx={{ flexGrow: 1 }}
          InputProps={{
            sx(theme) {
              return {
                [theme.breakpoints.down("xl")]: {
                  fontSize: "14px",
                  height: "28px",
                },
              }
            },
          }}
          inputProps={{
            style: {
              paddingBottom: "6px",
              paddingTop: "5px",
            },
          }}
        />
        <TooltipedDisabledButton
          tooltipTitle={tooltipTitle}
          disabled={selectedArticles.length < 1 || articlesLimitExceeded}
          onClick={() => {
            executeAIActionHandler(AllWebSocketActionTypes.AICustomRequest)
          }}
        >
          Eigene Anfrage
        </TooltipedDisabledButton>
      </FormControl>
    </Stack>
  )
}

const TooltipedDisabledButton: React.FC<
  {
    tooltipTitle: string
  } & ButtonProps
> = ({ tooltipTitle, ...buttonProps }) => {
  return (
    <Tooltip
      placement="bottom-start"
      title={tooltipTitle ? <Box fontSize="14px">{tooltipTitle}</Box> : null}
    >
      <span>
        <Button
          sx={(theme) => ({
            [theme.breakpoints.down("xl")]: {
              fontSize: "12px",
            },
          })}
          {...buttonProps}
        >
          {buttonProps.children}
        </Button>
      </span>
    </Tooltip>
  )
}

export default GridControls
