import { Box, CircularProgress, Dialog } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Article, useArticles } from "../GlobalContext/Providers/Articles"
import { usePreviewedArticle } from "../GlobalContext/Providers/PreviewedArticle"
import { ArticlePageView } from "./ArticleContentView"
import { useApiQueryWithMsalAuth } from "../api/ApiQueryHelpers"

const ArticleView: React.FC = () => {
  const navigate = useNavigate()
  const { articles } = useArticles()
  const { id: articleId } = useParams()
  const { value: previewedArticle } = usePreviewedArticle()
  const [isArticleLoading, setIsArticleLoading] = useState(true)
  const [viewedArticle, setViewedArticle] = useState<Article>()
  const { send: loadArticleByIdQuery } = useApiQueryWithMsalAuth()

  const loadArticleById = async (articleId?: string) => {
    if (articleId === "previewed") {
      setViewedArticle(previewedArticle as Article)
    } else {
      const loadedArticle = articles.find(
        (article) => article.id === (articleId ?? "")
      )
      if (loadedArticle) {
        setViewedArticle(loadedArticle)
      } else {
        const response = await loadArticleByIdQuery({
          uri: `/article/${articleId}`,
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        })
        if (response && response.ok) {
          const responseData = await response.json()
          setViewedArticle(responseData as Article)
        }
      }
    }
    setIsArticleLoading(false)
  }

  useEffect(() => {
    loadArticleById(articleId)
  }, [])

  return (
    <Dialog
      open
      onClose={() => {
        navigate("/")
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
          height: "85%",
          maxWidth: "80%",
          minWidth: "37%",
          width: "45%",
          resize: "horizontal",
        },
      }}
    >
      {viewedArticle ? (
        <ArticlePageView article={viewedArticle} />
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "26px",
          }}
        >
          {isArticleLoading ? <CircularProgress /> : <>404: Article Not Found</>}
        </Box>
      )}
    </Dialog>
  )
}

export default ArticleView
