import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material"
import { useRef, useState } from "react"
import { useApiQueryWithMsalAuth } from "../../../api/ApiQueryHelpers"
import { DatePickerWithFormat } from "../../../Components/DateTimePickerWithFormat"
import {
  Filters,
  mapFiltersToApiFormat,
} from "../../../GlobalContext/Providers/AvailableFilters"
import { usePreloader } from "../../../GlobalContext/Providers/Preloader"
import {
  SavedFilters,
  useSavedFilters,
} from "../../../GlobalContext/Providers/SavedFilters"
import { useSearchParams } from "../../../GlobalContext/Providers/SearchParams"
import { useSnackbar } from "../../../GlobalContext/Providers/Snackbar"
import {
  AgenciesMultiselectAutocomplete,
  PrioritiesMultiselectAutocomplete,
} from "../FiltersControls/FilterFormMultiselectAutocomplete"

const StoreSavedFiltersModal: React.FC<DialogProps> = ({ ...props }) => {
  const { setValue: setIsLoading } = usePreloader()
  const { refetch: refetchSavedFilters } = useSavedFilters()
  const { value: currentSearchParams } = useSearchParams()
  const [savedFilters, setSavedFilters] = useState<SavedFilters>({
    label: "",
    filters: { ...currentSearchParams.filters },
  })
  const { showSuccess, showError, showInfo } = useSnackbar()
  const saveFiltersForm = useRef<HTMLFormElement | null>(null)
  const { send: storeSavedFiltersQuery } = useApiQueryWithMsalAuth()

  const storeSavedFiltersHandler = async (e: React.FormEvent) => {
    e.preventDefault()
    const areFiltersNotEmpty =
      savedFilters.filters.agency?.length ||
      savedFilters.filters.dateFrom ||
      savedFilters.filters.dateTo ||
      savedFilters.filters.priority?.length ||
      savedFilters.filters.section?.length ||
      savedFilters.filters.text

    if (!areFiltersNotEmpty) {
      showInfo(
        "Bitte füllen Sie mindestens einige der Filter aus, um sie zu speichern!"
      )
      return
    }

    setIsLoading(true)
    const response = await storeSavedFiltersQuery({
      uri: "/saved-filters/save",
      method: "POST",
      body: JSON.stringify({
        filters: {
          ...savedFilters,
          filters: mapFiltersToApiFormat(savedFilters.filters),
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (response && response.status === 201) {
      showSuccess("Filter wurden erfolgreich gespeichert!")
      refetchSavedFilters()
      props.onClose && props.onClose(e, "backdropClick")
    } else {
      showError("Fehler beim Speichern der Filter!")
    }
    setIsLoading(false)
  }

  const setSavedFilterValue = <T extends keyof Filters>(
    filterKey: T,
    newValue: Filters[T]
  ) => {
    setSavedFilters({
      ...savedFilters,
      filters: {
        ...savedFilters.filters,
        [filterKey]: newValue,
      },
    })
  }

  return (
    <Dialog
      {...props}
      PaperProps={{
        sx(theme) {
          return {
            overflow: "hidden",
            height: "52%",
            maxWidth: "45%",
            width: "640px",
            minWidth: "640px",
            resize: "horizontal",
          }
        },
      }}
      disableRestoreFocus
    >
      <DialogTitle sx={{ p: 3, fontSize: 24 }}>
        Aktuelle Filter speichern
      </DialogTitle>
      <IconButton
        onClick={(e) => {
          props.onClose && props.onClose(e, "backdropClick")
        }}
        sx={{
          position: "absolute",
          right: 18,
          top: 25,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <form onSubmit={storeSavedFiltersHandler} ref={saveFiltersForm}>
          <TextField
            autoFocus
            required
            label="Beschriftung für gespeicherte Filter"
            fullWidth
            value={savedFilters.label}
            onChange={(e) => {
              setSavedFilters({ ...savedFilters, label: e.target.value })
            }}
            size="medium"
            sx={{
              mt: 2,
            }}
          />
          <Stack
            sx={(theme) => ({
              flexDirection: "row",
              flexWrap: "wrap",
              rowGap: "16px",
              justifyContent: "space-between",
              mt: 4,
              ".half-width-control": {
                width: "49%",
              },
            })}
          >
            <TextField
              label="Suchtext"
              fullWidth
              value={savedFilters.filters.text}
              onChange={(e) => {
                setSavedFilterValue("text", e.target.value)
              }}
            />
            <DatePickerWithFormat
              name="dateFrom"
              label="Datum von"
              value={savedFilters.filters.dateFrom as any}
              onChange={(value) => {
                setSavedFilterValue(
                  "dateFrom",
                  value ? (value as any) : undefined
                )
              }}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => {
                    setSavedFilterValue("dateFrom", undefined)
                  },
                },
              }}
              onAccept={(value) => {
                setSavedFilterValue(
                  "dateFrom",
                  value ? (value as any) : undefined
                )
              }}
              className="half-width-control"
            />
            <DatePickerWithFormat
              name="dateTo"
              label="Datum bis"
              value={savedFilters.filters.dateTo as any}
              onChange={(value) => {
                setSavedFilterValue("dateTo", value ? (value as any) : undefined)
              }}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => {
                    setSavedFilterValue("dateTo", undefined)
                  },
                },
              }}
              onAccept={(value) => {
                setSavedFilterValue("dateTo", value ? (value as any) : undefined)
              }}
              className="half-width-control"
            />
            <AgenciesMultiselectAutocomplete
              value={savedFilters.filters.agency}
              onChange={(e, value) => {
                setSavedFilterValue("agency", value)
              }}
              className="half-width-control"
              selectedTagsMaxHeight="130px"
            />
            <PrioritiesMultiselectAutocomplete
              value={savedFilters.filters.priority}
              onChange={(e, value) => {
                setSavedFilterValue("priority", value)
              }}
              className="half-width-control"
              selectedTagsMaxHeight="130px"
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          sx={(theme) => ({
            marginX: "auto",
            width: "24%",
          })}
          size="large"
          onClick={() => {
            saveFiltersForm.current && saveFiltersForm.current.requestSubmit()
          }}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StoreSavedFiltersModal
