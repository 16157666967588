import { Box } from "@mui/material"

const ArticlePriorityBlock: React.FC<{
  priority: number
}> = ({ priority }) => {
  const backgroundColors: { [key: number]: string } = {
    1: "#D86868",
    2: "#ECB6B6",
    3: "#F4EB8E",
    4: "#009990",
    5: "#9DD8D0",
  }

  const fontColors: { [key: number]: string } = {
    1: "#FFFFFF",
    2: "#3A3A3A",
    3: "#3A3A00",
    4: "#FFFFFF",
    5: "#3A3A3A",
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: backgroundColors[priority],
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        fontSize: "16px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: fontColors[priority],
      })}
    >
      {priority}
    </Box>
  )
}

export default ArticlePriorityBlock
