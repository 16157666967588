import { Check, Close, CopyAll } from "@mui/icons-material"
import { List, ListItem, ListItemButton, Paper, Popover } from "@mui/material"
import { htmlToText } from "html-to-text"
import React, { ReactNode, useState } from "react"
import { usePreviewedArticle } from "../../GlobalContext/Providers/PreviewedArticle"
import { copyToClipboardUnsafe, PreviewControlButton } from "../PreviewControls"

const CopyArticleToClipboardControl: React.FC = () => {
  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLElement | null>(null)
  const { value: previewedArticle } = usePreviewedArticle()
  const [copyBtnIcon, setCopyBtnIcon] = useState<ReactNode>(
    <CopyAll fontSize="small" />
  )

  const copyArticleToClipboardHandler = (copyType: "plainText" | "html") => {
    if (previewedArticle) {
      const header = previewedArticle.header
        ? previewedArticle.header + "<br/><br/>"
        : ""
      let articleContent = header + previewedArticle.body

      if (copyType === "plainText") {
        articleContent = htmlToText(articleContent)
      }

      navigator.clipboard?.writeText
        ? navigator.clipboard.writeText(articleContent)
        : copyToClipboardUnsafe(articleContent)

      setCopyBtnIcon(<Check fontSize="small" />)

      setTimeout(() => {
        setCopyBtnIcon(<CopyAll fontSize="small" />)
      }, 1000)
    }
  }

  return (
    <>
      <PreviewControlButton
        onClick={(e) => {
          setPopoverAnchorElement(popoverAnchorElement ? null : e.currentTarget)
        }}
      >
        {popoverAnchorElement ? <Close fontSize="small" /> : copyBtnIcon}
      </PreviewControlButton>
      <Popover
        open={!!popoverAnchorElement}
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => {
          setPopoverAnchorElement(null)
        }}
      >
        <Paper sx={{ mt: 0.4 }} elevation={5}>
          <List
            sx={{
              backgroundColor: "white",
              fontSize: "16px",
            }}
          >
            <ListItem
              disablePadding
              onClick={() => {
                copyArticleToClipboardHandler("plainText")
                setPopoverAnchorElement(null)
              }}
            >
              <ListItemButton>Text kopieren</ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                copyArticleToClipboardHandler("html")
                setPopoverAnchorElement(null)
              }}
            >
              <ListItemButton>HTML kopieren</ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </>
  )
}

export default CopyArticleToClipboardControl
