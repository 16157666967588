import { Box, Pagination } from "@mui/material"

const GridCustomFooter: React.FC<{
  numSelected: number
  numOfPages: number
  currentPage: number
  onPageChange: (pageNumber: number) => void
}> = ({ numSelected, numOfPages, currentPage, onPageChange }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        alignItems: "center",
        justifyContent: "center",
        py: 1,
      }}
    >
      <Box
        sx={{
          color: "#5B5B5B",
          fontSize: "14px",
          pl: 2,
        }}
      >
        {numSelected ? `Ausgewählt ${numSelected}` : null}
      </Box>
      <Pagination
        count={numOfPages}
        page={currentPage}
        onChange={(e, pageNumber) => onPageChange(pageNumber)}
        color="primary"
      />
    </Box>
  )
}

export default GridCustomFooter
