import { Close, Translate } from "@mui/icons-material"
import { List, ListItem, ListItemButton, Paper, Popover } from "@mui/material"
import React, { useEffect, useState } from "react"
import {
  AllWebSocketActionTypes,
  useWebSocketWithMsalAuth,
} from "../../api/ApiQueryHelpers"
import { useFeatureFlags } from "../../GlobalContext/Providers/FeatureConfig"
import { usePreloader } from "../../GlobalContext/Providers/Preloader"
import { usePreviewedArticle } from "../../GlobalContext/Providers/PreviewedArticle"
import { useSnackbar } from "../../GlobalContext/Providers/Snackbar"
import { PreviewControlButton } from "../PreviewControls"

export enum LanguagesToTranslateTo {
  Englisch = "english",
  Deutsch = "german",
  Spanisch = "spanish",
}

const TranslatePreviewedArticleControl: React.FC = () => {
  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLElement | null>(null)
  const { setValue: setIsLoading } = usePreloader()
  const { value: previewedArticle, setValue: setPreviewedArticle } =
    usePreviewedArticle()
  const { value: features } = useFeatureFlags()
  const { showError } = useSnackbar()
  const { run: runAIGeneration, stop: stopAIGeneration } =
    useWebSocketWithMsalAuth()

  const translatePreviewedArticleHandler = async (
    language: LanguagesToTranslateTo
  ) => {
    if (!previewedArticle || !previewedArticle.id) return
    setIsLoading(true)

    await runAIGeneration({
      uri: `/article/${previewedArticle.id}/translate/ws`,
      actionType: AllWebSocketActionTypes.AITranslate,
      urlParams: {
        temperature: `${features.AITemperature}`,
        language,
      },
      setCurrentState: (value) => {
        if (value) setIsLoading(false)
        setPreviewedArticle({ ...previewedArticle, body: value })
      },
      onWebSocketError: (errorMessage) => {
        setIsLoading(false)
        showError(errorMessage)
      },
    })
  }

  useEffect(() => {
    stopAIGeneration()
  }, [previewedArticle?.id])

  return (
    <>
      <PreviewControlButton
        disabled={!previewedArticle?.id}
        onClick={(e) => {
          setPopoverAnchorElement(popoverAnchorElement ? null : e.currentTarget)
        }}
      >
        {!!popoverAnchorElement ? (
          <Close fontSize="small" />
        ) : (
          <Translate fontSize="small" />
        )}
      </PreviewControlButton>
      <Popover
        open={!!popoverAnchorElement}
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => {
          setPopoverAnchorElement(null)
        }}
      >
        <Paper sx={{ mt: 0.4 }} elevation={5}>
          <List
            sx={{
              maxHeight: "240px",
              backgroundColor: "white",
              fontSize: "16px",
            }}
          >
            {Object.entries(LanguagesToTranslateTo).map(
              ([languageAlias, language]) => (
                <ListItem
                  key={language}
                  disablePadding
                  onClick={() => {
                    translatePreviewedArticleHandler(language)
                    setPopoverAnchorElement(null)
                  }}
                >
                  <ListItemButton>{languageAlias}</ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </Paper>
      </Popover>
    </>
  )
}

export default TranslatePreviewedArticleControl
