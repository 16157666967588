import { Box, Stack } from "@mui/material"
import dayjs from "dayjs"
import { usePreviewedArticle } from "../../GlobalContext/Providers/PreviewedArticle"
import { getEnvVar } from "../../../env"

const PrintView: React.FC = () => {
  const { value: previewedArticle } = usePreviewedArticle()
  if (!previewedArticle) {
    return null
  }

  return (
    <Box
      sx={{
        display: "none",
        displayPrint: "block",
        color: "black",
        textAlign: "left",
        width: "100vw",
      }}
    >
      <Box>
        {previewedArticle.header && (
          <Box
            sx={{ marginBottom: "20px", fontWeight: "bold", fontSize: "24px" }}
          >
            {previewedArticle.header}
          </Box>
        )}
        <Box
          sx={{
            fontSize: "18px",
            marginBottom: "26px",
            td: {
              paddingX: 2,
            },
          }}
          dangerouslySetInnerHTML={{ __html: previewedArticle.body }}
        />
        {(previewedArticle.agency || previewedArticle.report_date) && (
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "12px 16px",
            }}
          >
            <Box>{previewedArticle.agency}</Box>
            <Box>
              {dayjs(previewedArticle.report_date).format(
                getEnvVar("DATETIME_FORMAT") ?? undefined
              )}
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  )
}

export default PrintView
