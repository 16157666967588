import { Stack, TextField } from "@mui/material"
import { useSearchParams } from "../../GlobalContext/Providers/SearchParams"

const GridSearch: React.FC = () => {
  const { value: articleSearchParams, setFilter } = useSearchParams()

  return (
    <Stack sx={{ paddingY: "4px" }}>
      <TextField
        placeholder="Suchtext"
        sx={{ flexGrow: 1 }}
        onChange={(e) => {
          setFilter("text", e.target.value)
        }}
        value={articleSearchParams.filters.text ?? ""}
        InputProps={{
          sx(theme) {
            return {
              [theme.breakpoints.down("xl")]: {
                fontSize: "14px",
              },
            }
          },
        }}
      />
    </Stack>
  )
}

export default GridSearch
