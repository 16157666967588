import { ReactNode, createContext, useContext, useEffect, useState } from "react"

interface AdminTokenContext {
  adminToken: string
  setAdminToken: React.Dispatch<React.SetStateAction<string>>
}

const AdminTokenContext = createContext<AdminTokenContext>({
  adminToken: "",
  setAdminToken: () => {},
})

interface AdminTokenProviderProps {
  children: ReactNode[] | ReactNode
}

const AdminTokenContextProvider: React.FC<AdminTokenProviderProps> = ({
  children,
}) => {
  const [adminToken, setAdminToken] = useState(
    sessionStorage.getItem("admin-token") ?? ""
  )

  useEffect(() => {
    if (adminToken) {
      sessionStorage.setItem("admin-token", adminToken)
    }
  }, [adminToken])

  return (
    <AdminTokenContext.Provider value={{ adminToken, setAdminToken }}>
      {children}
    </AdminTokenContext.Provider>
  )
}

export default AdminTokenContextProvider

export const useAdminToken = () => useContext(AdminTokenContext)
