import { Configuration, RedirectRequest } from "@azure/msal-browser"
import { getEnvVar } from "../env"

export const msalConfig: Configuration = {
  auth: {
    clientId: getEnvVar("REACT_APP_CLIENT_ID") ?? "",
    authority: `https://login.microsoftonline.com/organizations`,
    redirectUri: getEnvVar("REACT_APP_REDIRECT_URI") ?? undefined,
  },
  cache: {
    cacheLocation: "localStorage", // localStorage would give SSO between tabs, sessionStorage is more secure
    storeAuthStateInCookie: false,
  },
}

export const loginRequest: RedirectRequest = {
  scopes: ["User.Read"],
}
