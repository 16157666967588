import { Alert, AlertColor, CircularProgress, Stack } from "@mui/material"
import MuiSnackbar, { SnackbarProps } from "@mui/material/Snackbar"
import React from "react"
import { useSnackbar } from "../GlobalContext/Providers/Snackbar"

const Snackbar: React.FC = () => {
  const { snackbarsState } = useSnackbar()

  if (!snackbarsState.length) return null

  return (
    <Stack
      sx={{
        flexDirection: "column",
        alignItems: "flex-end",
        rowGap: "12px",
        position: "fixed",
        right: "12px",
        top: "12px",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: "4px",
        padding: "8px",
        zIndex: "9000",
      }}
    >
      {snackbarsState.map(({ id, message, type }) => {
        return type === "progress" ? (
          <MuiSnackbar key={id} open>
            <Alert
              severity="info"
              icon={<CircularProgress size="20px" sx={{ marginRight: "4px" }} />}
            >
              {message}
            </Alert>
          </MuiSnackbar>
        ) : (
          <SnackbarWithAlert message={message} severity={type} key={id} />
        )
      })}
    </Stack>
  )
}

const SnackbarWithAlert: React.FC<
  Omit<SnackbarProps, "message"> & { severity: AlertColor; message: string }
> = ({ severity, message, ...props }) => {
  return (
    <MuiSnackbar open {...props}>
      <Alert severity={severity}>{message}</Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
